import { render, staticRenderFns } from "./dobra.vue?vue&type=template&id=3382acd6&scoped=true&"
import script from "./dobra.vue?vue&type=script&lang=js&"
export * from "./dobra.vue?vue&type=script&lang=js&"
import style0 from "./dobra.vue?vue&type=style&index=0&id=3382acd6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3382acd6",
  null
  
)

export default component.exports